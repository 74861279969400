import axios from "axios";
import { User } from "@auth0/auth0-react";
import { AxiosError } from "axios";

export const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL ||
  (window.location.hostname.includes("staging")
    ? "https://race-the-clock-git-main-chris-cooleys-projects.vercel.app"
    : "https://www.race-the-clock.com");

if (!API_BASE_URL) {
  console.error("VITE_API_BASE_URL is not set in the environment variables");
}

interface Item {
  id: number;
  name: string;
}

// Function to handle API errors
const handleApiError = (error: unknown) => {
  if (error instanceof Error && "response" in error) {
    const response = (error as { response: { data: string; status: number } })
      .response;
    console.error("API Error:", response.data);
    if (response.status === 401) {
      console.error("Unauthorized - Redirecting to login.");
      window.location.href = "/race-the-clock/"; // Assuming "/race-the-clock" is the basename
    }
  } else if ((error as AxiosError).request) {
    console.error("No response received:", (error as AxiosError).request);
  } else {
    if (error instanceof Error) {
      console.error("Error in API request setup:", error.message);
    } else {
      console.error("Unexpected error:", error);
    }
  }
  throw error;
};

export const getCurrentUser = async (
  getAccessTokenSilently: () => Promise<string>,
): Promise<User> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${API_BASE_URL}/users/me/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error("Could not fetch current user.");
  }
};

// Function to update the display name
export const updateDisplayName = async (
  displayNamePayload: { display_name: string },
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log("Updating display name with payload:", displayNamePayload);
    const token = await getAccessTokenSilently();
    await axios.put(
      `${API_BASE_URL}/users/me/display_name`,
      displayNamePayload,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Display name updated successfully.");
  } catch (error) {
    handleApiError(error);
  }
};

export const getSequences = async (
  userId: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log("Fetching sequences for user ID:", userId);
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${API_BASE_URL}/users/${userId}/sequences`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Sequences retrieved:", response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to create a sequence
export const createSequence = async (
  userId: string,
  name: string,
  sequence: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log("Creating sequence with name:", name);
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${API_BASE_URL}/sequences`,
      { user_id: userId, name, description: sequence },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Sequence created successfully:", response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to update a sequence
export const updateSequence = async (
  sequenceId: number,
  name: string,
  description: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log(`Updating sequence ID: ${sequenceId} with name: ${name}`);
    const token = await getAccessTokenSilently();
    const response = await axios.put(
      `${API_BASE_URL}/sequences/${sequenceId}`,
      { name, description },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Sequence updated successfully:", response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to delete a sequence
export const deleteSequence = async (
  sequenceId: number,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log("Deleting sequence ID:", sequenceId);
    const token = await getAccessTokenSilently();
    const response = await axios.delete(
      `${API_BASE_URL}/sequences/${sequenceId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Sequence deleted successfully.");
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Collection-related functions
export interface Collection {
  collection_id: number;
  name: string;
  description: string;
  created_at: string;
  category: string;
  user_id: number;
  creator_username: string;
  creator_display_name: string | null;
  items: CollectionItem[];
  type: string;
  item_count?: number;
  isSubscribed?: boolean;
}

interface CollectionItem {
  name: string;
  svg?: string;
  count?: number;
}

export const getCollections = async (
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${API_BASE_URL}/users/me/collections`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to create a collection
export const createCollection = async (
  name: string,
  description: string | Record<string, unknown>[],
  options: {
    visibility?: string;
    category?: string;
    type?: string;
    isPublic?: boolean;
  },
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const payload = {
      name,
      description: typeof description === "string" ? description : JSON.stringify(description),
      status: options.visibility || (options.isPublic ? "public" : "private"),
      category: options.category || "general",
      type: options.type || "standard"
    };

    console.log("Creating collection with payload:", payload);

    const response = await axios.post(
      `${API_BASE_URL}/collections`,
      payload,
      {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      },
    );

    console.log("Collection created successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating collection:", error);
    if (axios.isAxiosError(error)) {
      console.error("Full error response:", error.response?.data);
    }
    handleApiError(error);
    throw error;
  }
};

// Function to update a collection
export const updateCollection = async (
  collectionId: number,
  name: string,
  description: string,
  category: string,
  isPublic: boolean,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log(`Updating collection ID: ${collectionId} with name: ${name}`);
    const token = await getAccessTokenSilently();
    const response = await axios.put(
      `${API_BASE_URL}/collections/${collectionId}`,
      {
        name,
        description,
        category,
        status: isPublic ? "public" : "private",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Collection updated successfully:", response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to delete a collection
export const deleteCollection = async (
  collectionId: number,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log("Deleting collection ID:", collectionId);
    const token = await getAccessTokenSilently();
    const response = await axios.delete(
      `${API_BASE_URL}/collections/${collectionId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    console.log("Collection deleted successfully.");
    return response.data;
  } catch (error) {
    console.error("Failed to delete collection:", error);
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.data?.detail || "Unknown error occurred";
      throw new Error(`Failed to delete collection: ${errorMessage}`);
    }
    throw error;
  }
};

// Updated API function to include public/private status
export const saveCollection = async (
  username: string,
  collectionName: string,
  collectionData: Record<string, unknown>[],
  visibility: string,
  category: string,
  type: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${API_BASE_URL}/collections`,
      {
        name: collectionName,
        description: JSON.stringify(collectionData),
        status: visibility,
        category,
        type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error saving collection:", error);
    handleApiError(error);
    throw error; // Re-throw to handle in the component
  }
};

// Function to create a collection from a form
export const createCollectionFromForm = async (
  name: string,
  description: string,
  isPublic: boolean,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${API_BASE_URL}/collections`,
      {
        name,
        description,
        status: isPublic ? "public" : "private",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch public collections
export const fetchPublicCollections = async () => {
  try {
    const response = await axios.get<Collection[]>(
      `${API_BASE_URL}/collections/public`,
    );

    // Log the response to verify creator information
    console.log("Fetched public collections:", response.data);

    return response.data;
  } catch (error) {
    console.error("Error fetching public collections:", error);
    if (axios.isAxiosError(error)) {
      console.error("Axios error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
    }
    throw error;
  }
};

// Function to fetch items for a specific collection
export const fetchItemsForCollection = async (
  collectionId: number,
  token: string,
) => {
  try {
    const response = await axios.get<Item[]>(
      `${API_BASE_URL}/collections/${collectionId}/items`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to delete a collection by ID
export const deleteCollectionById = async (
  collectionId: number,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.delete(
      `${API_BASE_URL}/collections/${collectionId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to duplicate a collection
export const duplicateCollection = async (
  collectionToDuplicate: Collection,
  getAccessTokenSilently: () => Promise<string>,
) => {
  const newCollectionName = `${collectionToDuplicate.name} Copy`;
  const newCollection = {
    name: newCollectionName,
    description: collectionToDuplicate.description,
    category: collectionToDuplicate.category,
    status: "private",
    user_id: collectionToDuplicate.user_id,
  };

  try {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${API_BASE_URL}/collections`,
      newCollection,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch collections
export const fetchCollections = async (
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    console.log("Attempting to fetch collections...");
    const token = await getAccessTokenSilently();
    console.log("Got access token");
    
    const url = `${API_BASE_URL}/users/me/collections`;
    console.log("Fetching from URL:", url);
    
    const response = await axios.get(url, {
      headers: { 
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    
    console.log("Fetched collections:", response.data);
    return response.data;
  } catch (error: unknown) {
    console.error("Error fetching collections:", error);
    if (axios.isAxiosError(error)) {
      console.error("Axios error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
      });
    }
    handleApiError(error);
  }
};

export const subscribeToCollection = async (
  collectionId: number,
  getAccessTokenSilently: () => Promise<string>,
): Promise<Collection> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${API_BASE_URL}/collections/subscribe/${collectionId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      throw new Error("You are already subscribed to this collection.");
    }
    handleApiError(error);
    throw new Error("Could not subscribe to the collection.");
  }
};

export const searchPublicCollections = async (
  query: string,
  searchType: "name" | "creator"  // Remove "category" from type
) => {
  try {
    console.log(`Searching collections with query: ${query}, type: ${searchType}`);
    const response = await axios.get(`${API_BASE_URL}/public-collections/search`, {
      params: {
        query,
        search_type: searchType
      }
    });
    console.log('Search results:', response.data);
    return response.data;
  } catch (error) {
    console.error('Search error:', error);
    handleApiError(error);
    throw error;
  }
};

export const checkBackendHealth = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/health`);
    return response.data;
  } catch (error) {
    console.error("Error checking backend health:", error);
    throw error;
  }
};

export const checkSubscription = async (
  collectionId: number,
  getAccessTokenSilently: () => Promise<string>,
): Promise<boolean> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${API_BASE_URL}/collections/check-subscription/${collectionId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data.isSubscribed;
  } catch (error) {
    handleApiError(error);
    return false;
  }
};

// Function to fetch reports
export const fetchReports = async (
  getAccessTokenSilently: () => Promise<string>,
  page: number = 1,
  limit: number = 10,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${API_BASE_URL}/reports`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateUserRole = async (
  userId: string,
  role: string,
  token: string,
): Promise<User> => {
  try {
    console.log("Updating role for user ID:", userId, "to role:", role);
    const response = await axios.put(
      `${API_BASE_URL}/users/${encodeURIComponent(userId)}/role`,
      { role }, // Make sure this matches the RoleUpdate model in backend
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update user role:", error);
    throw new Error("Failed to update user role");
  }
};

// Function to submit feedback
export const submitFeedback = async (
  message: string,
  displayName: string,
  getAccessTokenSilently: () => Promise<string>,
  images?: File[],
) => {
  try {
    console.log("Starting feedback submission...");
    console.log("Number of images:", images?.length || 0);

    const formData = new FormData();
    formData.append("message", message);
    formData.append("page_url", window.location.href);
    formData.append("display_name", displayName);

    if (images && images.length > 0) {
      console.log("Processing images for upload...");
      images.forEach((image, index) => {
        console.log(
          `Adding image ${index + 1}:`,
          image.name,
          image.type,
          image.size,
        );
        formData.append("files", image);
      });
    }

    const headers: Record<string, string> = {};
    try {
      const token = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${token}`;
      console.log("Auth token obtained successfully");
    } catch {
      console.log("Submitting without authentication");
    }

    console.log("Sending request to server...");
    const response = await axios.post(
      `${API_BASE_URL}/api/feedback`,
      formData,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      },
    );

    console.log("Server response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Detailed error in submitFeedback:", {
      error,
      message: error instanceof Error ? error.message : "Unknown error",
      response: axios.isAxiosError(error) ? error.response?.data : undefined,
    });
    throw error;
  }
};

export const checkSubscriptionsBatch = async (
  collectionIds: number[],
  getAccessTokenSilently: () => Promise<string>,
): Promise<Record<string, boolean>> => {
  try {
    const token = await getAccessTokenSilently();
    console.log("Sending batch check request for collections:", collectionIds);
    const response = await axios.post(
      `${API_BASE_URL}/collections/check-subscriptions-batch`,
      { collection_ids: collectionIds }, // Wrap in an object
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
    console.log("Batch check response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in batch subscription check:", error);
    handleApiError(error);
    return {};
  }
};

export const fetchCompletionCounts = async (
  getAccessTokenSilently: () => Promise<string>,
): Promise<Record<number, number>> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${API_BASE_URL}/collections/completion-counts`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    return {};
  }
};

export const completeCollection = async (
  collectionId: number,
  getAccessTokenSilently: () => Promise<string>,
): Promise<void> => {
  try {
    const token = await getAccessTokenSilently();
    await axios.post(
      `${API_BASE_URL}/collections/${collectionId}/complete`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {
    handleApiError(error);
  }
};

interface Badge {
  badge_id: number;
  user_id: number;
  name: string;
  description: string;
  earned_at: string;
}

interface Achievement {
  achievement_id: number;
  user_id: number;
  name: string;
  description: string;
  earned_at: string;
}

export const fetchBadges = async (
  getAccessTokenSilently: () => Promise<string>,
): Promise<Badge[]> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${API_BASE_URL}/badges`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchAchievements = async (
  getAccessTokenSilently: () => Promise<string>,
): Promise<Achievement[]> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${API_BASE_URL}/achievements`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export interface GamePair {
  word: string;
  imageUrl: string;
}

export const saveGameCollection = async (
  name: string,
  description: GamePair[],
  type: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${API_BASE_URL}/game-collections/`,
      {
        name,
        description: JSON.stringify(description),
        type,
        category: "game",
        status: "private"
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error saving game collection:", error);
    handleApiError(error);
  }
};

export const updateGameCollection = async (
  collectionId: number,
  name: string,
  description: GamePair[],
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.put(
      `${API_BASE_URL}/game-collections/${collectionId}`,
      {
        name,
        description: JSON.stringify(description),
        type: "multiple_words",
        category: "game",
        status: "private"
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error updating game collection:", error);
    handleApiError(error);
  }
};

export const getGameCollections = async (
  type: "matching_game" | "multiple_words",
  getAccessTokenSilently: () => Promise<string>
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get<Collection[]>(`${API_BASE_URL}/collections`, {
      params: { type },
      headers: { Authorization: `Bearer ${token}` },
    });
    
    // Add logging to debug the response
    console.log("Retrieved game collections:", response.data);
    
    // Filter collections to ensure we only return ones with the correct type
    const filteredCollections = response.data.filter(collection => collection.type === type);
    return filteredCollections;
  } catch (error) {
    console.error("Error fetching game collections:", error);
    handleApiError(error);
    throw error;
  }
};
