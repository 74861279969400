import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchBadges, fetchAchievements } from "../../api";
import { tourStepsBadgesAchievements } from "./tourStepsBadgesAchievements";
import GuidedTour from "../../components/GuidedTour";
import { useCompletion } from "../../context/CompletionContext";
import BubbleText from "../../components/BubbleText";
import { useTheme } from "../../context/ThemeContext";
import {
  FaTrophy,
  FaMedal,
  FaStar,
  FaCrown,
  FaAward,
  FaCertificate,
  FaGem,
  FaHeart,
  FaCheckCircle,
  FaUserGraduate,
  FaCompass,
  FaShareAlt,
  FaBullseye,
  FaStopwatch,
  FaCalendarCheck,
  FaBookReader,
} from "react-icons/fa";
import {
  ALL_BADGES,
  ALL_ACHIEVEMENTS,
} from "../../constants/badgesAndAchievements";

interface Badge {
  badge_id: number;
  user_id: number;
  name: string;
  description: string;
  earned_at: string;
}

interface Achievement {
  achievement_id: number;
  user_id: number;
  name: string;
  description: string;
  earned_at: string;
}

const BadgesAchievements: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [badges, setBadges] = useState<Badge[]>([]);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { completionCounts } = useCompletion();
  const { theme } = useTheme();

  const getTextColorClass = () => {
    return theme.backgroundColor.toLowerCase() === "#000000"
      ? "text-white"
      : "text-black";
  };

  const badgeIcons = {
    "Bronze Badge for reading 30 letters in a minute": (
      <FaMedal className="text-amber-600" />
    ),
    "Silver Badge for reading 40 letters in a minute": (
      <FaMedal className="text-gray-400" />
    ),
    "Gold Badge for reading 50 letters in a minute": (
      <FaMedal className="text-yellow-400" />
    ),
    "Platinum Badge for reading 60 letters in a minute": (
      <FaTrophy className="text-cyan-400" />
    ),
    "Bronze Badge for matching 10 items": (
      <FaAward className="text-amber-600" />
    ),
    "Silver Badge for matching 20 items": <FaAward className="text-gray-400" />,
    "Gold Badge for matching 30 items": <FaAward className="text-yellow-400" />,
    "Platinum Badge for matching 40 items": (
      <FaCrown className="text-cyan-400" />
    ),
    "Master of the Advanced for completing an advanced session": (
      <FaStar className="text-yellow-400" />
    ),
    "Welcome Aboard Badge for first login": <FaGem className="text-blue-400" />,
    "Session Slayer Medal for completing 5 sessions": (
      <FaCertificate className="text-green-500" />
    ),
    "Explorer of Worlds Badge for exploring all games": (
      <FaCompass className="text-purple-500" />
    ),
    "Collection Connoisseur Trophy for creating 3 collections": (
      <FaTrophy className="text-yellow-400" />
    ),
    "Sharing is Caring Badge for sharing a collection": (
      <FaShareAlt className="text-pink-500" />
    ),
    "Quiz Whiz Trophy for achieving 100% on a quiz": (
      <FaBullseye className="text-red-500" />
    ),
    "Challenge Accepted Badge for participating in a timed challenge": (
      <FaStopwatch className="text-blue-500" />
    ),
    "Loyalty Badge for logging in for 7 consecutive days": (
      <FaCalendarCheck className="text-green-500" />
    ),
  };

  const achievementIcons = {
    "Read 30 letters in a minute": <FaBookReader className="text-amber-600" />,
    "Read 40 letters in a minute": <FaBookReader className="text-gray-400" />,
    "Read 50 letters in a minute": <FaBookReader className="text-yellow-400" />,
    "Read 60 letters in a minute": <FaBookReader className="text-cyan-400" />,
    "Match 10 items": <FaCheckCircle className="text-amber-600" />,
    "Match 20 items": <FaCheckCircle className="text-gray-400" />,
    "Match 30 items": <FaCheckCircle className="text-yellow-400" />,
    "Match 40 items": <FaCheckCircle className="text-cyan-400" />,
    "Complete an Advanced Session": (
      <FaUserGraduate className="text-purple-500" />
    ),
    "First Login": <FaHeart className="text-pink-500" />,
    "Completed 5 Sessions": <FaStar className="text-yellow-400" />,
    "Explored All Games": <FaCompass className="text-blue-500" />,
    "Created 3 Collections": <FaGem className="text-emerald-500" />,
    "Shared a Collection": <FaShareAlt className="text-indigo-500" />,
    "Achieved 100% on a Quiz": <FaBullseye className="text-red-500" />,
    "Participated in a Timed Challenge": (
      <FaStopwatch className="text-orange-500" />
    ),
    "Logged in for 7 consecutive days": (
      <FaCalendarCheck className="text-green-500" />
    ),
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const [fetchedBadges, fetchedAchievements] = await Promise.all([
          fetchBadges(getAccessTokenSilently),
          fetchAchievements(getAccessTokenSilently),
        ]);
        setBadges(fetchedBadges);
        setAchievements(fetchedAchievements);
      } catch (error) {
        console.error("Failed to load badges and achievements", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const tourCompleted = localStorage.getItem(
      `tourCompleted_badgesAchievements`,
    );
    if (!tourCompleted) {
      setIsTourRunning(true);
    }
  }, []);

  const handleTourComplete = () => {
    setIsTourRunning(false);
    localStorage.setItem(`tourCompleted_badgesAchievements`, "true");
  };

  // Call the function without arguments
  const steps = tourStepsBadgesAchievements();

  return (
    <div className={`page-container ${getTextColorClass()}`}>
      {isLoading ? (
        <p className="inherit">Loading badges and achievements...</p>
      ) : (
        <>
          <h1 className="inherit mb-8 text-3xl font-bold">
            <BubbleText>Badges & Achievements</BubbleText>
          </h1>
          <p className="inherit">
            Badges and Achievements: Create badges for students who achieve
            specific goals, like "Read 60 Letters in a Minute" or "Complete an
            Advanced Session."
          </p>
          <p className="inherit">
            Milestones for Teachers: Teachers can set milestones for their
            students, rewarding them as they improve, fostering motivation.
          </p>

          <div className="mt-8 w-full max-w-2xl">
            <div className="badges-section">
              <h2 className="inherit text-2xl font-semibold">Badges</h2>
              <ul className="inherit list-disc pl-5">
                {ALL_BADGES.map((badgeName, index) => {
                  const earned = badges.some(
                    (badge) => badge.name === badgeName,
                  );
                  return (
                    <li
                      key={index}
                      className={`mb-2 flex items-center gap-3 ${earned ? "opacity-100" : "opacity-50"}`}
                    >
                      <span className="text-2xl">
                        {badgeIcons[badgeName as keyof typeof badgeIcons]}
                      </span>
                      <span>{badgeName}</span>
                      {!earned && (
                        <span className="text-sm text-gray-500">
                          (Not earned yet)
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="achievements-section mt-8">
              <h2 className="inherit text-2xl font-semibold">Achievements</h2>
              <ul className="inherit list-disc pl-5">
                {ALL_ACHIEVEMENTS.map((achievementName, index) => {
                  const earned = achievements.some(
                    (achievement) => achievement.name === achievementName,
                  );
                  return (
                    <li
                      key={index}
                      className={`mb-2 flex items-center gap-3 ${earned ? "opacity-100" : "opacity-50"}`}
                    >
                      <span className="text-2xl">
                        {
                          achievementIcons[
                            achievementName as keyof typeof achievementIcons
                          ]
                        }
                      </span>
                      <span>{achievementName}</span>
                      {!earned && (
                        <span className="text-sm text-gray-500">
                          (Not achieved yet)
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>

            <h2 className="inherit text-2xl font-semibold">
              Completion Counts
            </h2>
            <ul className="inherit">
              {Object.entries(completionCounts).length > 0 ? (
                Object.entries(completionCounts).map(
                  ([collectionId, count]) => (
                    <li key={collectionId}>
                      Collection ID {collectionId}: Completed {count} times
                    </li>
                  ),
                )
              ) : (
                <li>No completion counts available.</li>
              )}
            </ul>
          </div>

          <GuidedTour
            steps={steps}
            isRunning={isTourRunning}
            onComplete={handleTourComplete}
            currentStep={currentTourStep}
            onStepChange={setCurrentTourStep}
            tourName="badgesAchievements"
          />
        </>
      )}
    </div>
  );
};

export default BadgesAchievements;
