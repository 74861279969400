import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  interface AppState {
    returnTo?: string;
    state?: string;
  }

  const onRedirectCallback = (appState?: AppState) => {
    const storedState = localStorage.getItem("auth0_state");

    if (storedState !== appState?.state) {
      localStorage.removeItem("auth0_state");
      navigate("/your-collections");
      return;
    }

    const redirectTo = appState?.returnTo || "/your-collections";
    navigate(redirectTo);
    localStorage.removeItem("auth0_state");
  };

  const login = async () => {
    const state = generateRandomState();
    localStorage.setItem("auth0_state", state);
    await loginWithRedirect({ appState: { state } });
  };

  const generateRandomState = (): string => {
    return Math.random().toString(36).substring(2, 15); // Generates a random string
  };

  return (
    <>
      {!isAuthenticated && (
        <button
          type="button"
          onClick={login}
          style={{
            position: "absolute",
            left: "-9999px", // Move off-screen
          }}
        >
          Log In
        </button>
      )}
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: import.meta.env.VITE_AUTH0_REDIRECT_URI,
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: "openid profile email",
        }}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    </>
  );
};

export default Auth0ProviderWithHistory;
