import React, { useEffect, useState } from "react";
import { tourStepsGames } from "./tourStepsGames";
import GuidedTour from "../../components/GuidedTour";
import { Link } from "react-router-dom";
import BubbleText from "../../components/BubbleText";
import { useTheme } from "../../context/ThemeContext";
import { FaRandom, FaBuffer, FaClock } from "react-icons/fa";

const Games: React.FC = () => {
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const tourCompleted = localStorage.getItem("tourCompleted");
    if (!tourCompleted) {
      setIsTourRunning(true);
    }
  }, []);

  const handleTourComplete = () => {
    setIsTourRunning(false);
    localStorage.setItem("tourCompleted", "true");
  };

  const handleTourStepChange = (step: number) => {
    setCurrentTourStep(step);
  };

  const getTextColorClass = () => {
    return theme.backgroundColor.toLowerCase() === "#000000"
      ? "text-white"
      : "text-black";
  };

  return (
    <div className={`page-container ${getTextColorClass()}`}>
      <h1 className="inherit mb-8 text-3xl font-bold">
        <BubbleText>Games</BubbleText>
      </h1>
      <p className="inherit">
        Welcome to the Games page! Here are some fun activities to enhance
        engagement.
      </p>
      {isLoading ? (
        <p className="inherit">Loading games...</p>
      ) : (
        <div className="w-full max-w-2xl">
          <Link
            to="/games/matching-game"
            className="matching-game mt-8 block hover:opacity-80"
          >
            <FaRandom
              className="mr-2 inline-block text-2xl"
              style={{ color: "#4A90E2" }}
            />
            <h2 className="inherit text-2xl font-semibold">Matching Game</h2>
            <p className="inherit">
              Match the letters with the corresponding images!
            </p>
          </Link>

          <Link
            to="/games/multiple-words-game"
            className="multiple-words-game mt-8 block hover:opacity-80"
          >
            <FaBuffer
              className="mr-2 inline-block text-2xl"
              style={{ color: "#E94E77" }}
            />
            <h2 className="inherit text-2xl font-semibold">
              Multiple Words Game
            </h2>
            <p className="inherit">
              Drag and connect words to their corresponding cards!
            </p>
          </Link>

          <Link
            to="/games/timed-challenges"
            className="timed-challenges-game mt-8 block hover:opacity-80"
          >
            <FaClock
              className="mr-2 inline-block text-2xl"
              style={{ color: "#F5A623" }}
            />
            <h2 className="inherit text-2xl font-semibold">Timed Challenges</h2>
            <p className="inherit">
              Complete as many challenges as you can within the time limit!
            </p>
          </Link>
        </div>
      )}
      <GuidedTour
        steps={tourStepsGames()}
        isRunning={isTourRunning}
        onComplete={handleTourComplete}
        currentStep={currentTourStep}
        onStepChange={handleTourStepChange}
        tourName="games"
      />
    </div>
  );
};

export default Games;
