import React, { createContext, useContext, useState } from "react";

interface UserRoleContextType {
  userRole: string;
  setUserRole: (role: string) => void;
}

const UserRoleContext = createContext<UserRoleContextType | undefined>(
  undefined,
);

export const UserRoleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userRole, setUserRole] = useState(
    localStorage.getItem("userRole") || "student",
  );

  const handleSetUserRole = (role: string) => {
    localStorage.setItem("userRole", role);
    setUserRole(role);
  };

  return (
    <UserRoleContext.Provider
      value={{ userRole, setUserRole: handleSetUserRole }}
    >
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserRoleProvider");
  }
  return context;
};
