export const scienceTermsData = {
  physics: [
    "Gravity",
    "Velocity",
    "Acceleration",
    "Force",
    "Mass",
    "Energy",
    "Pressure",
    "Temperature",
    "Density",
    "Volume",
    "Momentum",
    "Friction",
    "Wavelength",
    "Frequency",
    "Amplitude",
    "Magnetism",
    "Electricity",
    "Current",
    "Voltage",
    "Resistance",
    "Nuclear Fission",
    "Nuclear Fusion",
    "Quantum",
    "Relativity",
    "Thermodynamics",
  ],
  chemistry: [
    "Atom",
    "Molecule",
    "Chemical Reaction",
    "Element",
    "Compound",
    "Solution",
    "Solvent",
    "Solute",
    "Acid",
    "Base",
    "pH Scale",
    "Oxidation",
    "Reduction",
    "Catalyst",
    "Enzyme",
    "Isotope",
    "Ion",
    "Electron",
    "Proton",
    "Neutron",
    "Covalent Bond",
    "Ionic Bond",
    "Metallic Bond",
    "Periodic Table",
    "Valence",
  ],
  biology: [
    "Cell",
    "DNA",
    "RNA",
    "Evolution",
    "Photosynthesis",
    "Ecosystem",
    "Mitochondria",
    "Nucleus",
    "Chromosome",
    "Gene",
    "Protein",
    "Enzyme",
    "Metabolism",
    "Homeostasis",
    "Respiration",
    "Digestion",
    "Circulation",
    "Nervous System",
    "Immune System",
    "Hormone",
    "Bacteria",
    "Virus",
    "Species",
    "Adaptation",
    "Natural Selection",
  ],
  earthScience: [
    "Atmosphere",
    "Lithosphere",
    "Hydrosphere",
    "Biosphere",
    "Plate Tectonics",
    "Volcano",
    "Earthquake",
    "Climate",
    "Weather",
    "Erosion",
    "Deposition",
    "Glacier",
    "Ocean Current",
    "Rock Cycle",
    "Mineral",
    "Fossil",
    "Sediment",
    "Groundwater",
    "Hurricane",
    "Tornado",
    "Greenhouse Effect",
    "Carbon Cycle",
    "Water Cycle",
    "Nitrogen Cycle",
    "Ozone Layer",
  ],
};
