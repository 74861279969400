export const nursingTermsData = {
  basicCare: [
    "Vital Signs",
    "Blood Pressure",
    "Heart Rate",
    "Respiratory Rate",
    "Temperature",
    "Oxygen Saturation",
    "Patient Assessment",
    "Documentation",
    "Fall Prevention",
    "Bed Making",
    "Personal Hygiene",
    "Oral Care",
    "Bathing",
    "Positioning",
    "Range of Motion",
    "Ambulation",
    "Transfer Techniques",
    "Nutrition Assessment",
    "Hydration Status",
    "Sleep Pattern",
  ],
  medications: [
    "Medication Administration",
    "Drug Interactions",
    "Dosage Calculation",
    "Pain Management",
    "Antibiotic Therapy",
    "Insulin Administration",
    "Medication Side Effects",
    "Drug Classifications",
    "Medication Safety",
    "IV Medications",
    "Oral Medications",
    "Subcutaneous Injection",
    "Intramuscular Injection",
    "Medication Reconciliation",
    "PRN Medications",
    "Controlled Substances",
    "Medication Storage",
    "Drug Compatibility",
    "Medication Education",
    "Adverse Reactions",
  ],
  procedures: [
    "Intravenous Therapy",
    "Catheterization",
    "Wound Care",
    "Cardiopulmonary Resuscitation",
    "Electrocardiogram",
    "Nasogastric Tube",
    "Tracheostomy Care",
    "Chest Tube Management",
    "Surgical Prep",
    "Post-op Care",
    "Sterile Technique",
    "Isolation Precautions",
    "Blood Transfusion",
    "Specimen Collection",
    "Oxygen Therapy",
    "Suctioning",
    "Dressing Change",
    "Central Line Care",
    "PICC Line Management",
    "Dialysis Care",
  ],
  specializedCare: [
    "Critical Care",
    "Emergency Care",
    "Pediatric Care",
    "Geriatric Care",
    "Oncology Care",
    "Psychiatric Care",
    "Maternal Care",
    "Palliative Care",
    "Rehabilitation",
    "Pain Assessment",
    "Wound Assessment",
    "Neurological Assessment",
    "Respiratory Assessment",
    "Cardiac Assessment",
    "Gastrointestinal Assessment",
    "Mental Status Assessment",
    "End of Life Care",
    "Hospice Care",
    "Chronic Disease Management",
    "Acute Care",
  ],
  patientEducation: [
    "Discharge Planning",
    "Health Promotion",
    "Disease Prevention",
    "Medication Teaching",
    "Lifestyle Modification",
    "Self-Care Management",
    "Family Education",
    "Support Systems",
    "Community Resources",
    "Health Literacy",
    "Patient Rights",
    "Care Coordination",
    "Follow-up Care",
    "Home Care Instructions",
    "Diet Education",
    "Exercise Guidelines",
    "Stress Management",
    "Smoking Cessation",
    "Diabetes Education",
    "Wound Care Instructions",
  ],
};
